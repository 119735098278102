import { type } from "@testing-library/user-event/dist/type"
import { Button, Col, Form, Input, Modal, Row, Select, Spin, Typography, Upload } from "antd"
import ImgCrop from "antd-img-crop"
import { lazy, useEffect, useState } from "react"
import { GoPlus } from "react-icons/go";
import { mainAction } from "../../redux/mainAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { fileUrl } from "../../constant/const";

const AddCarModal = ({ modalOpen, setModalOpen, selectCar, cancelBtn, submitBtn }) => {
    const token = useSelector((state) => state?.common?.authData?.accessToken)
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [fileList, setFileList] = useState([])
    const [loading, setLoading] = useState(false);
    const [catgryData, setCatgryData] = useState([])
    const [subCatgryData, setSubCatgryData] = useState([])
    const [carType, setCarType] = useState(false)
    const [pereferType, setPereferType] = useState('')
    const [driverList, setDriverList] = useState([])

    const onCancelClick = () => {
        setModalOpen(false)
        cancelBtn()
    }

    const formFields = [
        {
            label: 'Add Car Model',
            placeholder: "Enter Car Model",
            type: 'text',
            name: 'car_model',
            rule: {
                required: true,
                message: 'Please enter car model!',
            },
        },
        {
            label: 'Is Automatic ?',
            type: 'selectBox',
            name: 'is_automatic',
            rule: {
                required: true,
                message: 'Please select option!',
            },
            option: [
                {
                    value: '',
                    label: 'Select Option'
                },
                {
                    value: 'Yes',
                    label: "Yes"
                },
                {
                    value: 'No',
                    label: "No"
                },
            ]
        },
        // {
        //     label: 'Have AC?',
        //     type: 'selectBox',
        //     name: 'have_ac',
        //     rule: {
        //         required: true,
        //         message: 'Please select option!',
        //     },
        //     option: [
        //         {
        //             value: '',
        //             label: 'Select Option'
        //         },
        //         {
        //             value: 'Yes',
        //             label: "Yes"
        //         },
        //         {
        //             value: 'No',
        //             label: "No"
        //         },
        //     ]
        // },
        {
            label: 'Total Seats',
            placeholder: "Enter total seats",
            type: 'number',
            name: 'total_seats',
            rule: {
                required: false,
                message: 'Please enter total seats!',
            },
        },
        // {
        //     label: 'Total Doors',
        //     placeholder: "Enter total doors",
        //     type: 'number',
        //     name: 'total_doors',
        //     rule: {
        //         required: true,
        //         message: 'Please enter total doors!',
        //     },
        // },
        {
            label: 'MPG(Miles per gallon)',
            placeholder: "Enter mpg",
            type: 'number',
            name: 'mpg',
            rule: {
                required: false,
                message: 'Please enter mpg!',
            },
        },
        // {
        //     label: 'Bag Capacity',
        //     placeholder: "Enter bag capacity",
        //     type: 'number',
        //     name: 'bag_capacity',
        //     rule: {
        //         required: true,
        //         message: 'Please enter mpg!',
        //     },
        // },
        {
            label: 'Smoking Allowed',
            type: 'selectBox',
            name: 'smoking_allowed',
            rule: {
                required: false,
                message: 'Please select option!',
            },
            option: [
                {
                    value: '',
                    label: 'Select Option'
                },
                {
                    value: 'Yes',
                    label: "Yes"
                },
                {
                    value: 'No',
                    label: "No"
                },
            ]
        },

        {
            label: 'Zip Code',
            placeholder: "Enter zip code",
            type: 'number',
            name: 'zip_code',
            rule: {
                required: true,
                message: 'Please enter zip code!',
            },
        },
        // pereferType==1&&
        // {
        //     label: 'Select Driver',
        //     type: 'selectBox',
        //     name: 'driver_id',
        //     rule: {
        //         required: true,
        //         message: 'Please select driver!',
        //     },
        //     option: [
        // {
        //     value: '',
        //     label: 'Select Option'
        // },
        // ...driverList?.map((item) => {
        //     return {
        //         value: item?._id,
        //         label: item?.name
        //     }
        // })
        //     ]
        // },

    ]

    useEffect(() => {
        if (selectCar?._id) {
            form.setFieldsValue({
                car_model: selectCar?.car_model,
                is_automatic: selectCar?.is_automatic,
                have_ac: selectCar?.have_ac,
                total_seats: selectCar?.total_seats,
                total_doors: selectCar?.total_doors,
                mpg: selectCar?.mpg,
                bag_capacity: selectCar?.bag_capacity,
                smoking_allowed: selectCar?.smoking_allowed,
                zip_code: selectCar?.zip_code,
                zip_code: selectCar?.zip_code,
                car_type: selectCar?.car_type?._id,
                car_sub_type: selectCar?.car_sub_type?._id,
                car_option: selectCar?.car_option,
                driver_id: selectCar?.driver_id,
            })
            subCatgryList(selectCar?.car_type?._id)
            setFileList([
                {
                    uid: selectCar?._id,
                    name: selectCar?.name,
                    status: 'done',
                    url: `${fileUrl}${selectCar.car_image}`,
                },
            ]);
        }
        getDriverListData()
        getCatgryList()
    }, [])

    const getDriverListData = () => {
        setLoading(true)
        const successCb = (res) => {
            setLoading(false)
            setDriverList(res?.data)
        }
        const failureCb = (res) => {
            setLoading(false)
            setDriverList([])
            // toast.error(res?.message)
        }
        dispatch(mainAction(`affiliate/listDrivers`, 'post', '', successCb, failureCb, { token: token }, false))
    }
    const getCatgryList = (page, limit) => {
        setLoading(true)
        const successCb = (res) => {
            setLoading(false)
            let mainData = res?.data
            setCatgryData(mainData)
        }
        const failureCb = (res) => {
            setLoading(false)
        }
        dispatch(mainAction(`affiliate/find`, 'get', '', successCb, failureCb, { token: token }, false))
    }

    const subCatgryList = (id) => {
        let payload = {
            category_id: id
        }
        const successCb = (res) => {
            let mainData = res?.data
            setSubCatgryData(mainData)
        }
        const failureCb = (res) => {
        }
        dispatch(mainAction(`affiliate/findSubCategoryById`, 'post', payload, successCb, failureCb, false, { token: token }))
    }

    const handleChangeImage = ({ fileList: newFileList }) => setFileList(newFileList);
    const handleCarType = (value) => {
        setCarType(value)
        subCatgryList(value)
    }
    const handlePerference = (value) => {
        setPereferType(value)
    }
    const driverUpdate = (driverId, cardId) => {
        let payload = {
            isAlreadyAvailable: 0,
            id: driverId,
            // car_id: cardId
        }
        const successCb = (res) => {
        }
        const failureCb = (res) => {
            toast.error(res?.message)
        }
        dispatch(mainAction(`affiliate/updateOne`, 'patch', payload, successCb, failureCb, false,{ token: token }))
    }
    const onSubmitForm = (values) => {
        setLoading(true)
        let formData = new FormData();
        if (fileList?.[0]?.originFileObj) {
            formData.append('image', fileList?.[0]?.originFileObj)
        }
        formData.append('car_model', values?.car_model)
        // formData.append('have_ac', values?.have_ac)
        // formData.append('total_doors', values?.total_doors)
        // formData.append('bag_capacity', values?.bag_capacity)
        formData.append('zip_code', values?.zip_code)
        formData.append('is_automatic', values?.is_automatic)
        if (values?.smoking_allowed) {
            formData.append('smoking_allowed', values?.smoking_allowed)
        }
        if (values?.mpg) {
            formData.append('mpg', values?.mpg)
        }
        if (values?.total_seats) {
            formData.append('total_seats', values?.total_seats)
        }
        formData.append('car_type', values?.car_type)
        if (values?.driver_id) {
            formData.append('driver_id', values?.driver_id)
        }
        formData.append('car_option', values?.car_option)
        console.log("values", values);
        // return
        if (values?.car_sub_type) {
            formData.append('car_sub_type', values?.car_sub_type)
        }
        if (selectCar?._id) {
            formData.append('id', selectCar?._id)
        }
        const successCb = (res) => {
            let cardId = res?.data?._id
            setLoading(false)
            submitBtn(res)
            setModalOpen(false)
            if (values?.driver_id) {
                driverUpdate(values?.driver_id, cardId)
            }
            
            toast.success(res?.message)
        
            if (selectCar?._id) {
                form.resetFields()
                setFileList([])
            }
        }
        const failureCb = (res) => {
            setLoading(false)
            toast.error(res?.message)
        }
        dispatch(mainAction(`affiliate/add_car`, 'post', formData, successCb, failureCb, { token: token }, true))
    }



    return (
        <div className="">
            <Modal
                title={<Typography style={{ color: '#2c458b', fontSize: '1rem' }}>
                    {
                        selectCar?._id ? "UPDATE CARE" : "ADD NEW CAR"
                    }
                </Typography>}
                open={modalOpen}
                onCancel={() => onCancelClick()}
                footer={false}
                width={950}
            >
                <div className="">
                    <Form name="form" form={form} onFinish={onSubmitForm} layout="vertical" className="modal_form" >
                        <Row gutter={[25, 25]}>
                            {
                                formFields?.map((item, indx) => {
                                    return (
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12} key={indx}>
                                            <Form.Item name={item?.name} label={item?.label} rules={[
                                                item?.rule,
                                            ]} >
                                                {
                                                    (item?.type === "text" || item?.type == "number") &&
                                                    <Input className="input_box" style={{ boxShadow: 'none' }} placeholder={item?.placeholder} onKeyPress={(event) => {
                                                        if (item?.type === "number") {
                                                            const keyCode = event.keyCode || event.which;
                                                            const keyValue = String.fromCharCode(keyCode);
                                                            // Allow only numeric characters and control keys
                                                            const regex = /^[0-9\b]+$/;
                                                            if (!regex.test(keyValue) || event.target.value.length >= 10) {
                                                                event.preventDefault();
                                                            }
                                                        }
                                                    }} />
                                                }
                                                {
                                                    item?.type == "selectBox" &&
                                                    <Select
                                                        className="carSelector"
                                                        options={item?.option}
                                                        defaultValue={""}
                                                    />
                                                }
                                            </Form.Item>
                                        </Col>
                                    )
                                })
                            }

                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <Form.Item name={'car_option'} label={'Preference'} rules={[
                                    {
                                        required: true,
                                        message: 'Please select preference!',
                                    }
                                ]} >
                                    <Select
                                        options={[
                                            {
                                                value: '',
                                                label: 'Select Option'
                                            },
                                            {
                                                value: 1,
                                                label: 'Hire Me'
                                            },
                                            {
                                                value: 2,
                                                label: 'Rent Me'
                                            },
                                        ]}
                                        defaultValue={''}
                                        onChange={(value) => handlePerference(value)}
                                    />
                                </Form.Item>
                            </Col>
                            {
                                pereferType == 1 &&
                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <Form.Item name={'driver_id'} label={'Select Driver'} rules={[
                                        {
                                            required: true,
                                            message: 'Please select option!',
                                        }
                                    ]} >
                                        <Select
                                            options={[
                                                {
                                                    value: '',
                                                    label: 'Select Option'
                                                },
                                                ...driverList?.map((item) => {
                                                    return {
                                                        value: item?._id,
                                                        label: item?.name
                                                    }
                                                })
                                            ]}
                                            defaultValue={''}
                                        />
                                    </Form.Item>
                                </Col>
                            }
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <Form.Item name={'car_type'} label={'Car Type'} rules={[
                                    {
                                        required: true,
                                        message: 'Please select option!',
                                    }
                                ]} >
                                    <Select
                                        options={[
                                            {
                                                value: '',
                                                label: 'Select Car Type'
                                            },
                                            ...catgryData?.map((ele) => {
                                                return {
                                                    value: ele?._id,
                                                    label: ele?.name
                                                }
                                            }),
                                        ]}
                                        defaultValue={''}
                                        onChange={(value) => handleCarType(value)}
                                    />
                                </Form.Item>
                            </Col>
                            {
                                subCatgryData?.length > 0 &&
                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <Form.Item name={'car_sub_type'} label={'Coach Type'} rules={[
                                        {
                                            required: true,
                                            message: 'Please select option!',
                                        }
                                    ]} >
                                        <Select
                                            options={[
                                                {
                                                    value: '',
                                                    label: 'Select Coach Type'
                                                },
                                                ...subCatgryData?.map((ele) => {
                                                    return {
                                                        value: ele?._id,
                                                        label: ele?.name
                                                    }
                                                })
                                            ]}
                                            defaultValue={''}
                                        />
                                    </Form.Item>
                                </Col>
                            }
                        </Row>
                        <div className="antd_upload mt-3">
                            <Typography>Car Image</Typography>
                            <div className="mt-3">
                                <ImgCrop rotationSlider>
                                    <Upload
                                        onChange={handleChangeImage}
                                        fileList={fileList}
                                        listType="picture-card"
                                    >
                                        {
                                            fileList <= 1 &&
                                            <button
                                                style={{
                                                    border: 0,
                                                    background: 'none',
                                                }}
                                                type="button"
                                            >
                                                {/* {loading ? <Spin /> : <GoPlus color="grey" size={50} />} */}
                                                <div
                                                    style={{
                                                        marginTop: 8,
                                                    }}
                                                >
                                                    Upload
                                                </div>
                                            </button>
                                        }
                                    </Upload>
                                </ImgCrop>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-end gap-3 mt-5">
                            <Button type="ghost" style={{}} className="bg-danger modal_button" onClick={() => onCancelClick()}>Cancel</Button>
                            <Button loading={loading} type="ghost" htmlType="submit" style={{ backgroundColor: '#2c458b' }} className="modal_button">Submit</Button>
                        </div>
                    </Form>
                </div>

            </Modal>
        </div>
    )
}
export default AddCarModal