import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router"
import { HashRouter } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import OutRoutes from "./OutRoutes";

import Loader from "../components/Loader";
import NoDataFound from "../screen/NoDataFound";
import ChauffeursFormCover from "../screen/home/ChauffeursFormCover";
const ReservationComp = lazy(() => import('../screen/home/ReservationComp'));
const Home = lazy(() => import('../screen/home/Home'));
const ContactUs = lazy(() => import('../screen/ContactUs'));
const Privacy = lazy(() => import('../screen/Privacy'));
const Terms = lazy(() => import('../screen/Terms'));

const Index = () => {
    return (
        <>
            <Suspense fallback={<Loader />}>
                <HashRouter>
                    <Routes>
                        <Route element={<OutRoutes />}>
                            <Route path="/" element={<ReservationComp />} />
                            <Route path="/operation" element={<Home />} />
                            <Route path="/contact_us" element={<ContactUs />} />
                            <Route path="/privacy&policy" element={<Privacy />} />
                            <Route path="/terms&conditions" element={<Terms />} />
                            <Route path="/chauffeurs" element={<ChauffeursFormCover />} />
                        </Route>
                        <Route path="/quick-reservation" element={<ReservationComp />} />
                        <Route path="*" element={<NoDataFound />} />
                    </Routes>
                    <ToastContainer autoClose={1000} />
                </HashRouter>
            </Suspense>
        </>
    )
}
export default Index