import { apiCall } from "../api";
import { baseUrl } from "../constant/const";

export const mainAction = (url, method = 'post', data, successCb, failureCb, formData = false, token = "") => async (dispatch) => {

    // const token1 = token || '';
    const csrfToken = '';

    let config = {
        method: method,
        url: `${baseUrl}${url}`,
        headers: {
            Authorization: token ? `Bearer ${token}` : null,
            "Content-Type": formData ? "multipart/form-data" : "application/json",
            'x-csrf-token': csrfToken,
        }
    };

    if (data) {
        config.data = data
    }

    let response = await apiCall(config, dispatch);
    console.log("11111 response", response)
    try {
        if (response?.data?.status) {
            // console.log("1111111111 response pehle", response)
            return successCb(response?.data);
        } else {
            // console.log("1111111111 response pehle ka else", response)
            return failureCb(response?.data);
        }
    } catch (error) {
        // console.log("1111111111 response pehle ka else 222", response)
        return failureCb(response?.data);
    }
};


