import React from 'react'
import ChauffeursForm from './ChauffeursForm'

const ChauffeursFormCover = () => {
    return (
        <div className='affiliate-cover'>
            <ChauffeursForm />
        </div>
    )
}

export default ChauffeursFormCover